button {
    color:gray;
    font-family: "Roboto Mono", monospace;
    font-style: bold;
    text-decoration: underline;
    /* font-size: adjust; */

    border: none;
    background: none;
    cursor: pointer;
    white-space: nowrap;
    display: inline;





    color: orangered;

    font-weight: bold;

    font-size: medium;
}
.sidebar2 {
    margin-top: -4.5svw;
    position: absolute;
    right: 4svw;
  }

.linea {
    margin-left: -30svw;
    opacity: 50%;;
}


