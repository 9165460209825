body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "SpaceMono";   /*Can be any text*/
  src: local("SpaceMono"),
    url("./fonts/SpaceMono-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "RobotoSlab";   /*Can be any text*/
  src: local("RobotoSlab"),
    url("./fonts/RobotoSlab-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";   /*Can be any text*/
  src: local("Roboto"),
    url("./fonts/Roboto-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Satoshi";   /*Can be any text*/
  src: local("Satoshi"),
    url("./fonts/Minimal-Mono-Regular.otf") format("opentype");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
