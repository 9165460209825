html {
  scroll-behavior:smooth;
  overflow-x: hidden;
}
.App {
  /* text-align: center; */
  margin-left:4svw;
  margin-right:4svw;
  margin-top: 3rem;
  font-size: 1em;
  /* font-size: 1.2em; */
  border: black;
  border-width: 1emma;
  }
@media screen and (max-width: 700px) {
  .App {
    font-size: .77rem;
    font-size: .8rem;
    font-size: .9em; {/* este teamaño es para sans serif*/}
    /* background-color: yellow; */
  }
}
.nombre {
  margin-left: 8svw;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  /* visibility: hidden; */
}
.intro {
  /* font-family: Arial, Helvetica, sans-serif; */
  font-family: monospace;
  /* text-transform: uppercase; */
  /* font-weight: bold; */
  text-align: justify;
  /* font-size: medium; */
  /* line-height: 1.25rem; */
  
  margin-left: 8svw;
  margin-right: -4svw;
  /* margin-top: -6svw; */
  /* font-weight: bold; */
  /* letter-spacing: .06em; */
  /* word-spacing: .1em; */
  color: rgb(41, 39, 39);
  color:rgb(108, 108, 108);
  /* font-weight: 200; */
}
.iz {
  /* line-height: .75em; */
  width: 40%;
  
}
.der {
  width: 30%;
  margin-left: 0svw;
  margin-right: 4svw;
  text-align: left;
}


.Blog {
  /* text-align: center; */
  margin-left:8svw;
  margin-right:4svw;
  margin-top: 8rem;
  /* font-size: .8rem; */
  font-size: small;
  margin-bottom: 20rem;
  /* font-weight: 400; */
  color: rgba(0, 0, 0, 0.834);
  color: black;
  /* color: rgb(41, 39, 39); */
  /* font-weight: bold; */
  /* text-align: justify; */
  line-height: 1.25rem;
  font-family: 'Roboto Mono';


}
.Blog p {
  margin-right: auto;
  margin-bottom: 0rem;
  width: 550px;
  line-height: 1.2rem;
}



@media screen and (max-width: 700px) {
  .nombre {
    margin-left: 0svw;
  }
  
  .intro {
    margin-right: 0svw;
    margin-left: 0svw;
  }
  .Blog {
    margin-right: 0svw; 
    /* dejar un intemedio con margin-right 4svw */
    margin-left: 0svw;
    font-size: .8rem;
    line-height: 1.1rem;
  }
  .Blog p {
    margin-right: 0%;
    width: auto;
    text-align: justify;
  }
  
}



.fuente2 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  
}
.Seccion2 {
  /* background-image: linear-gradient(180deg, rgb(0, 0, 0) 40%, rgba(18,18,18,1) 50%); */
  background-color: rgb(164, 58, 58);
  background-color: black;
  margin-left: -4svw;
  margin-right: -4svw;
  /* padding-bottom: 10svw; */
  color: beige;
  color:#e3e1e1;
  color: rgb(236, 236, 236);
  /* line-height: 1.2em;   */
  text-align: center;
  /* border-radius: 20px; */
  height:3350px;
  overflow:hidden;
  
  
}
.Seccion2 p {
  padding-left: 4svw;
  padding-right: 4svw;
}
.Seccion2 > div > img {
  max-width: 50%;
  height: auto;
  /* display: block; */
  margin-right: 1svw;
  margin-left: 1svw;
}
.Span{
  padding-top:6svw;
  background-color: rgba(0, 0, 0, 0);
  text-align: center;
  font-size:clamp(14px, 10vw, 8rem);
  /* margin-bottom: -20%; */
  
  /* margin-left: -4svw;
  margin-right: -4svw; */
}
.Spanseparador{
  padding-top:6svw;
  background-color: rgba(241,240,239,255);
  text-align: center;
  font-size:clamp(14px, 10vw, 8rem);
  /* margin-bottom: -20%; */
  
  /* margin-left: -4svw;
  margin-right: -4svw; */
  /* border-radius: 20px; */
  margin-left: 1svw;
  margin-right: 1svw;
  height: 500px;
  margin-top: -4px;
}
.Span > h1 {
  font-family:Arial, Helvetica, sans-serif;
  color:rgb(248, 248, 248);
  color: rgba(241,240,239,255); /*exacto al fondo de sección 1 */
  /* color: rgb(198, 227, 205); */
  /* mix-blend-mode:color-dodge; */
  margin:0;
  margin-top:-9.32%;
  /* margin-top:-9.56%; el de arriba era 38 */
  margin-bottom: -0.5%;
  margin-bottom: 40px;
  letter-spacing: .01em;

  
}
@media screen and (max-width: 600px) {
  .Span > h1 {
    margin-top:-9.6%;
  }
}

.gif {
  border: #c2c2c2;
  /* border-radius: 1em; */
  border-color: aliceblue;
  /* width: 70%;  */
  width: 100%;
  height: 190px;
  background-size: contain;
  /* zoom:130%; */
  background-clip: border-box;
  padding-top: 20%;
  padding-bottom: 5%;
  /* padding-bottom: 30%; */
  background-image: url("./img/gifcrop.gif"); 
  /* background-repeat: no-repeat; */
  /* background-size:cover, no-repeat; */
  /* margin-left: -4svw;
  margin-right: -4svw;  */
  background-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  /* filter: blur(2px);
  -webkit-filter: blur(2px); */
}
@media screen and (max-width: 600px) {
  .gif {
    padding-top: 10svw;
    margin-top: -50px;
    width: 94%;
    border: #c2c2c2;
    /* border-radius: 1em; */
    height: 40vh;
    height: 70svw;
    zoom:80%;
    background-repeat: no-repeat;
    margin-bottom: -30px;
    /* height: 90vh; */
    /* background-size: cover; */

    /* padding-top: 10svw;
    width: 95%;
    border: #c2c2c2;
    border-radius: 1em;
    height: 70svw;
    zoom:80%;
    background-repeat: no-repeat; */
  }
  
}
.fondoblur {
  overflow: hidden;
  position: relative;
  margin-top: -50%;
  margin-bottom: -10%;
  padding: 20svw;
  padding-left: 0;
  padding-right: 0;
  zoom: 100%;
  width:100%;
  background-image: url("./img/gif.gif"); 
  background-position:top;
  background-repeat: no-repeat;
  /* margin-left: -4svw;
  margin-right: -4svw;  */
  opacity: 0.1;
  -webkit-filter: blur(800px) invert() saturate(10);
  mix-blend-mode:exclusion;
  overflow:visible;
  pointer-events: none;
  /* mix-blend-mode:multiply; */
}
@media screen and (max-width: 800px) {
  .fondoblur {
    margin-top: -60%;
  }
  }
body {
  /* background-image: url("./img/movil 2.jpeg");  */
  /* background-color: rgba(209, 204, 204, 0.592); */
  /* background-color: rgba(224, 219, 219, 0.432); */
  background-color: rgba(241,240,239,255);
  /* background-size: cover; */
  
  /* font-family:'Roboto Mono', monospace;
  font-family: monospace;
  font-size: .95em; */
  /* font-family: monospace; */
  
  /* font-family: 'Satoshi'; */
  /* font-family: 'Satoshi'; */
  /* font-family: 'Helvetica Neue', sans-serif; */
  /* font-family: monospace; */
  /* color:rgb(48, 48, 48); */
  
}
header {
  padding-top: 0%;
  text-align: top;
  margin-top: -5%;
  margin-bottom: 4%;
  position: sticky;
  top: 0;
  border-radius: 20px;
}
header > div > img {
  padding-bottom: 0.3rem;
  width: 30%;
}
.Punto {
  width: 1svw;
  margin-bottom: -2.5svw;
}

.buttonHeader {
  font-size: 1rem;
  margin-left: 0.5rem;
  padding: 0.5rem 1rem;
  border: none;
  /* border-radius: 4px; */
  /* background-color: #555; */
  background: none;
  color: #c2c2c2;
  /* mix-blend-mode: luminosity; */
  cursor: pointer;
  font-family: 'Roboto Mono';
  font-weight: 400;
}











/*sonido*/
.Sound {
  /* transition: background-color 0.5s ease; */
  -webkit-transition: background-image 0.5s ease;
    
    /* -moz-transition: background-image 2s ease-in-out;
    -webkit-transition: background-image 2s ease-in-out;
    -o-transition: background-image 2s ease-in-out;
    transition: background-image 2s ease-in-out; */
  
}

.Sound.playing {

  background-color: white; /*Cambia el color de fondo a blanco cuando está sonando*/
  background-image: url("./img/gradiente.gif");

  background: grayscale(100%);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-repeat: repeat-y;
  background-size: cover;
  text-indent: -9999px;
  /*esto usaba:*/
  /* background-repeat: no-repeat;
  background-position: top;
  background-position-x: 100svw;
  background-size: contain; */


  /* background: 60%; */
  
  mix-blend-mode:luminosity;
  mix-blend-mode:saturation;
  mix-blend-mode:normal;
  -webkit-filter:blur(2px) brightness(1.1);
  /* brightness(1.1); */
  /* width: 300%; */
  /* display:flow-root; */

  /*esto usaba:*/
  /* margin-right: -4svw;
  margin-left: -4svw;
  margin-top: -100svw;
  margin-bottom: -1000svw;
  padding:100svw; */

  overflow: visible;
  text-align:center;
  object-fit: contain;

  
}

/* @media screen and (max-width: 800px) {
  .Sound.playing {
    zoom: 200%;
    margin-top: -100svw;
    margin-bottom: -20000svw;
    text-align:center;
    object-fit: fill;
    background-repeat: repeat-y;
    overflow: visible;
    
  }
  
} */







.buttonFilter. {
  background-color: blue;




}





.Slideshow {
    animation: moveSlideshow 12s linear infinite;
  }

.Monospaced {
    font-family: 'Roboto Mono', monospace;
  }
.Descripción {
  margin-top: 50px;
}
.descr{
  font-size: 1.1em;
  margin-top: 4svw;
}
.intxt {
  margin-top: 4svw;
  margin-left: auto;
  margin-right: auto;
  text-align: right;
  /* width: 50svw; */
  font-size: smaller;
  color: #e5e5e5;
  opacity: .6;
}
.botones {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media screen and (max-width: 800px) {
  .intxt {
    width: 90svw;
    text-align: left;
    margin-left: -4vw;
    margin-top: -1vh;
    margin-bottom: 4vh;
  }
}


 .diagrama {
  /* size: svw; */
  /* display: block; */
  /* margin-right: 10svw;
  margin-left: 10svw; */
  width: 14svw;
  margin-bottom: 4svw;
  /* float: right; */
  /* margin-top: 2svw; */
  opacity: .5;
  /* display: block; */
  visibility: hidden;
  
}







.row {
  display: flex;
  /* width: 90vw; */
  margin-left: 4vw;
}


.column1 {
  flex: 40%;
  text-align: left;
}
.column {
  flex: 55%;
  text-align: left;
  /* margin-left: -5%; */
  /* margin-right: 3%; */
}

.footer_n {
  display: flex;
  margin-left: 4vw;
  align-items: center;
}
.column_n {
  flex: 40%;
  text-align: left;
}

.column_m {
  flex: 55%;
}

.KeepReading {
  right:5vw;
  position: absolute;
}

@media screen and (max-width:600px) {
  .row {
    margin-left: auto;
  }
  .column1 {
    margin-left: 4vw;
  }
  
}

@media screen and (max-width: 700px) { 
  .row {
    flex-direction: column;
  }
  .column {
    width: 100%;
  }
}



mark  {
    color: orangered;
    background: none;
    font-weight: bold;
    text-decoration: underline;
  }





 a:link, a:visited {
  /* color: rgb(90, 92, 90); */
  color: rgb(23, 23, 23);
  color: orangered;
  text-decoration: underline;
    font-weight: bold;
    
 }
  
  
 a:link:active, a:visited:active {
   color: rgb(239, 145, 236);
 }





 .barra {
  width: 104vw;
  margin-left: -4vw;
 }
 .barra2 {
  position: absolute;
  width:100vw;
  left: 0;
  margin-top: -20px;

 }
 .manos {
  mix-blend-mode: lighten;
  position: absolute;
  margin-left: -33vw;
  margin-right: auto;
  width: 80vw;
 }
 .uno{
  /* margin-left: -90vw; */
 }
/* 
 .circulo {
  width: 40% ;
  margin-top: -5svw;
  /* margin-right: 5svw; */
  /* position: fixed; */
  /* margin-left: 40svw;
  mix-blend-mode:darken;
  border: #c2c2c2;
  border-radius: 20;
  border-style: double;
  } */
 

/* .videomacka {
  text-align: right;
  color: #c2c2c2;
  margin-top: -2svw;
  mix-blend-mode:color-dodge; 
  margin-right: 13svw;

} */

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

