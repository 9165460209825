body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "SpaceMono";   /*Can be any text*/
  src: local("SpaceMono"),
    url(/static/media/SpaceMono-Regular.96985f7a.ttf) format("truetype");
}
@font-face {
  font-family: "RobotoSlab";   /*Can be any text*/
  src: local("RobotoSlab"),
    url(/static/media/RobotoSlab-Regular.2100b4a0.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto";   /*Can be any text*/
  src: local("Roboto"),
    url(/static/media/Roboto-Regular.8a36205b.ttf) format("truetype");
}
@font-face {
  font-family: "Satoshi";   /*Can be any text*/
  src: local("Satoshi"),
    url(/static/media/Minimal-Mono-Regular.461d6a4a.otf) format("opentype");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior:smooth;
  overflow-x: hidden;
}
.App {
  /* text-align: center; */
  margin-left:4svw;
  margin-right:4svw;
  margin-top: 3rem;
  font-size: 1em;
  /* font-size: 1.2em; */
  border: black;
  border-width: 1emma;
  }
@media screen and (max-width: 700px) {
  .App {
    font-size: .77rem;
    font-size: .8rem;
    font-size: .9em; {/* este teamaño es para sans serif*/}
    /* background-color: yellow; */
  }
}
.nombre {
  margin-left: 8svw;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  /* visibility: hidden; */
}
.intro {
  /* font-family: Arial, Helvetica, sans-serif; */
  font-family: monospace;
  /* text-transform: uppercase; */
  /* font-weight: bold; */
  text-align: justify;
  /* font-size: medium; */
  /* line-height: 1.25rem; */
  
  margin-left: 8svw;
  margin-right: -4svw;
  /* margin-top: -6svw; */
  /* font-weight: bold; */
  /* letter-spacing: .06em; */
  /* word-spacing: .1em; */
  color: rgb(41, 39, 39);
  color:rgb(108, 108, 108);
  /* font-weight: 200; */
}
.iz {
  /* line-height: .75em; */
  width: 40%;
  
}
.der {
  width: 30%;
  margin-left: 0svw;
  margin-right: 4svw;
  text-align: left;
}


.Blog {
  /* text-align: center; */
  margin-left:8svw;
  margin-right:4svw;
  margin-top: 8rem;
  /* font-size: .8rem; */
  font-size: small;
  margin-bottom: 20rem;
  /* font-weight: 400; */
  color: rgba(0, 0, 0, 0.834);
  color: black;
  /* color: rgb(41, 39, 39); */
  /* font-weight: bold; */
  /* text-align: justify; */
  line-height: 1.25rem;
  font-family: 'Roboto Mono';


}
.Blog p {
  margin-right: auto;
  margin-bottom: 0rem;
  width: 550px;
  line-height: 1.2rem;
}



@media screen and (max-width: 700px) {
  .nombre {
    margin-left: 0svw;
  }
  
  .intro {
    margin-right: 0svw;
    margin-left: 0svw;
  }
  .Blog {
    margin-right: 0svw; 
    /* dejar un intemedio con margin-right 4svw */
    margin-left: 0svw;
    font-size: .8rem;
    line-height: 1.1rem;
  }
  .Blog p {
    margin-right: 0%;
    width: auto;
    text-align: justify;
  }
  
}



.fuente2 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  
}
.Seccion2 {
  /* background-image: linear-gradient(180deg, rgb(0, 0, 0) 40%, rgba(18,18,18,1) 50%); */
  background-color: rgb(164, 58, 58);
  background-color: black;
  margin-left: -4svw;
  margin-right: -4svw;
  /* padding-bottom: 10svw; */
  color: beige;
  color:#e3e1e1;
  color: rgb(236, 236, 236);
  /* line-height: 1.2em;   */
  text-align: center;
  /* border-radius: 20px; */
  height:3350px;
  overflow:hidden;
  
  
}
.Seccion2 p {
  padding-left: 4svw;
  padding-right: 4svw;
}
.Seccion2 > div > img {
  max-width: 50%;
  height: auto;
  /* display: block; */
  margin-right: 1svw;
  margin-left: 1svw;
}
.Span{
  padding-top:6svw;
  background-color: rgba(0, 0, 0, 0);
  text-align: center;
  font-size:clamp(14px, 10vw, 8rem);
  /* margin-bottom: -20%; */
  
  /* margin-left: -4svw;
  margin-right: -4svw; */
}
.Spanseparador{
  padding-top:6svw;
  background-color: rgba(241,240,239,255);
  text-align: center;
  font-size:clamp(14px, 10vw, 8rem);
  /* margin-bottom: -20%; */
  
  /* margin-left: -4svw;
  margin-right: -4svw; */
  /* border-radius: 20px; */
  margin-left: 1svw;
  margin-right: 1svw;
  height: 500px;
  margin-top: -4px;
}
.Span > h1 {
  font-family:Arial, Helvetica, sans-serif;
  color:rgb(248, 248, 248);
  color: rgba(241,240,239,255); /*exacto al fondo de sección 1 */
  /* color: rgb(198, 227, 205); */
  /* mix-blend-mode:color-dodge; */
  margin:0;
  margin-top:-9.32%;
  /* margin-top:-9.56%; el de arriba era 38 */
  margin-bottom: -0.5%;
  margin-bottom: 40px;
  letter-spacing: .01em;

  
}
@media screen and (max-width: 600px) {
  .Span > h1 {
    margin-top:-9.6%;
  }
}

.gif {
  border: #c2c2c2;
  /* border-radius: 1em; */
  border-color: aliceblue;
  /* width: 70%;  */
  width: 100%;
  height: 190px;
  background-size: contain;
  /* zoom:130%; */
  background-clip: border-box;
  padding-top: 20%;
  padding-bottom: 5%;
  /* padding-bottom: 30%; */
  background-image: url(/static/media/gifcrop.89cdaef2.gif); 
  /* background-repeat: no-repeat; */
  /* background-size:cover, no-repeat; */
  /* margin-left: -4svw;
  margin-right: -4svw;  */
  background-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  /* filter: blur(2px);
  -webkit-filter: blur(2px); */
}
@media screen and (max-width: 600px) {
  .gif {
    padding-top: 10svw;
    margin-top: -50px;
    width: 94%;
    border: #c2c2c2;
    /* border-radius: 1em; */
    height: 40vh;
    height: 70svw;
    zoom:80%;
    background-repeat: no-repeat;
    margin-bottom: -30px;
    /* height: 90vh; */
    /* background-size: cover; */

    /* padding-top: 10svw;
    width: 95%;
    border: #c2c2c2;
    border-radius: 1em;
    height: 70svw;
    zoom:80%;
    background-repeat: no-repeat; */
  }
  
}
.fondoblur {
  overflow: hidden;
  position: relative;
  margin-top: -50%;
  margin-bottom: -10%;
  padding: 20svw;
  padding-left: 0;
  padding-right: 0;
  zoom: 100%;
  width:100%;
  background-image: url(/static/media/gif.1085b408.gif); 
  background-position:top;
  background-repeat: no-repeat;
  /* margin-left: -4svw;
  margin-right: -4svw;  */
  opacity: 0.1;
  -webkit-filter: blur(800px) invert() saturate(10);
  mix-blend-mode:exclusion;
  overflow:visible;
  pointer-events: none;
  /* mix-blend-mode:multiply; */
}
@media screen and (max-width: 800px) {
  .fondoblur {
    margin-top: -60%;
  }
  }
body {
  /* background-image: url("./img/movil 2.jpeg");  */
  /* background-color: rgba(209, 204, 204, 0.592); */
  /* background-color: rgba(224, 219, 219, 0.432); */
  background-color: rgba(241,240,239,255);
  /* background-size: cover; */
  
  /* font-family:'Roboto Mono', monospace;
  font-family: monospace;
  font-size: .95em; */
  /* font-family: monospace; */
  
  /* font-family: 'Satoshi'; */
  /* font-family: 'Satoshi'; */
  /* font-family: 'Helvetica Neue', sans-serif; */
  /* font-family: monospace; */
  /* color:rgb(48, 48, 48); */
  
}
header {
  padding-top: 0%;
  text-align: top;
  margin-top: -5%;
  margin-bottom: 4%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  border-radius: 20px;
}
header > div > img {
  padding-bottom: 0.3rem;
  width: 30%;
}
.Punto {
  width: 1svw;
  margin-bottom: -2.5svw;
}

.buttonHeader {
  font-size: 1rem;
  margin-left: 0.5rem;
  padding: 0.5rem 1rem;
  border: none;
  /* border-radius: 4px; */
  /* background-color: #555; */
  background: none;
  color: #c2c2c2;
  /* mix-blend-mode: luminosity; */
  cursor: pointer;
  font-family: 'Roboto Mono';
  font-weight: 400;
}











/*sonido*/
.Sound {
  /* transition: background-color 0.5s ease; */
  -webkit-transition: background-image 0.5s ease;
    
    /* -moz-transition: background-image 2s ease-in-out;
    -webkit-transition: background-image 2s ease-in-out;
    -o-transition: background-image 2s ease-in-out;
    transition: background-image 2s ease-in-out; */
  
}

.Sound.playing {

  background-color: white; /*Cambia el color de fondo a blanco cuando está sonando*/
  background-image: url(/static/media/gradiente.797abff4.gif);

  background: grayscale(100%);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-repeat: repeat-y;
  background-size: cover;
  text-indent: -9999px;
  /*esto usaba:*/
  /* background-repeat: no-repeat;
  background-position: top;
  background-position-x: 100svw;
  background-size: contain; */


  /* background: 60%; */
  
  mix-blend-mode:luminosity;
  mix-blend-mode:saturation;
  mix-blend-mode:normal;
  -webkit-filter:blur(2px) brightness(1.1);
  /* brightness(1.1); */
  /* width: 300%; */
  /* display:flow-root; */

  /*esto usaba:*/
  /* margin-right: -4svw;
  margin-left: -4svw;
  margin-top: -100svw;
  margin-bottom: -1000svw;
  padding:100svw; */

  overflow: visible;
  text-align:center;
  object-fit: contain;

  
}

/* @media screen and (max-width: 800px) {
  .Sound.playing {
    zoom: 200%;
    margin-top: -100svw;
    margin-bottom: -20000svw;
    text-align:center;
    object-fit: fill;
    background-repeat: repeat-y;
    overflow: visible;
    
  }
  
} */







.buttonFilter. {
  background-color: blue;




}





.Slideshow {
    -webkit-animation: moveSlideshow 12s linear infinite;
            animation: moveSlideshow 12s linear infinite;
  }

.Monospaced {
    font-family: 'Roboto Mono', monospace;
  }
.Descripción {
  margin-top: 50px;
}
.descr{
  font-size: 1.1em;
  margin-top: 4svw;
}
.intxt {
  margin-top: 4svw;
  margin-left: auto;
  margin-right: auto;
  text-align: right;
  /* width: 50svw; */
  font-size: smaller;
  color: #e5e5e5;
  opacity: .6;
}
.botones {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media screen and (max-width: 800px) {
  .intxt {
    width: 90svw;
    text-align: left;
    margin-left: -4vw;
    margin-top: -1vh;
    margin-bottom: 4vh;
  }
}


 .diagrama {
  /* size: svw; */
  /* display: block; */
  /* margin-right: 10svw;
  margin-left: 10svw; */
  width: 14svw;
  margin-bottom: 4svw;
  /* float: right; */
  /* margin-top: 2svw; */
  opacity: .5;
  /* display: block; */
  visibility: hidden;
  
}







.row {
  display: flex;
  /* width: 90vw; */
  margin-left: 4vw;
}


.column1 {
  flex: 40% 1;
  text-align: left;
}
.column {
  flex: 55% 1;
  text-align: left;
  /* margin-left: -5%; */
  /* margin-right: 3%; */
}

.footer_n {
  display: flex;
  margin-left: 4vw;
  align-items: center;
}
.column_n {
  flex: 40% 1;
  text-align: left;
}

.column_m {
  flex: 55% 1;
}

.KeepReading {
  right:5vw;
  position: absolute;
}

@media screen and (max-width:600px) {
  .row {
    margin-left: auto;
  }
  .column1 {
    margin-left: 4vw;
  }
  
}

@media screen and (max-width: 700px) { 
  .row {
    flex-direction: column;
  }
  .column {
    width: 100%;
  }
}



mark  {
    color: orangered;
    background: none;
    font-weight: bold;
    text-decoration: underline;
  }





 a:link, a:visited {
  /* color: rgb(90, 92, 90); */
  color: rgb(23, 23, 23);
  color: orangered;
  text-decoration: underline;
    font-weight: bold;
    
 }
  
  
 a:link:active, a:visited:active {
   color: rgb(239, 145, 236);
 }





 .barra {
  width: 104vw;
  margin-left: -4vw;
 }
 .barra2 {
  position: absolute;
  width:100vw;
  left: 0;
  margin-top: -20px;

 }
 .manos {
  mix-blend-mode: lighten;
  position: absolute;
  margin-left: -33vw;
  margin-right: auto;
  width: 80vw;
 }
 .uno{
  /* margin-left: -90vw; */
 }
/* 
 .circulo {
  width: 40% ;
  margin-top: -5svw;
  /* margin-right: 5svw; */
  /* position: fixed; */
  /* margin-left: 40svw;
  mix-blend-mode:darken;
  border: #c2c2c2;
  border-radius: 20;
  border-style: double;
  } */
 

/* .videomacka {
  text-align: right;
  color: #c2c2c2;
  margin-top: -2svw;
  mix-blend-mode:color-dodge; 
  margin-right: 13svw;

} */

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


.sidebar {
    margin-top: 4.5rem;
    position: absolute;
    right: 4svw;
    font-size: small;
    visibility: hidden;
  }
  
  /* .sidebar_icon {
    background-color: #333;
    color: white;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  } */
/*   
  .sidebar_icon:hover {
    background-color: #555;
  }
  
  .sidebar_icon i {
    font-size: 1.7rem;
  }
  
  .sidebar_items {
    background-color: #333;
    color: white;
    width: 200px;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  
  .sidebar_item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  } */
  
  .sidebar_item:hover {
    color: #000000;
    font-weight: bold;
  }
  
  /* .sidebar_item i { */
    /* font-size: 1.3rem; */
    /* margin-right: 1svw; */
  /* } */
  
   .sidebar_item p {
    font-size: smaller;
  } 

  
  

@media screen and (max-width: 800px) {
    
    .sidebar_item p {
        font-size: smaller;
      } 
    .sidebar_text {
        font-size: smaller;
    }
    .sidebar{
        visibility:hidden;
        padding: 4rem;
    }
}   




@font-face {
    font-family: "Grotesk";   /*Can be any text*/
    src: local("EuropaGrotesk"),
      url(/static/media/EuropaGroteskSH-Med.8caf9417.otf) format("opentype");
  }

.Eljuegodelfuturo{
    background-image: url(/static/media/casco.c528509c.png);
    background-position-x: 4.3svw;
    background-position-y: 11vw;
    background-size: 95%;
    background-repeat: no-repeat;
    overflow: hidden;
}


.jfuturo {
    /* opacity: 0%; */
  width: 100%;
  /* display: inline-block; */
  /* font-family: "Grotesk"; */
  margin-top: -10.2svw;
  object-position: 0svw -8svw;
  
}

.jfuturo_txt {
    /* margin-bottom: -12.9svw; */
    padding: 0;
    /* display: inline-block; */
    /* margin-bottom: -17.9svw; */
    /* margin-bottom: 20svw; */
    white-space: nowrap;
    background-color: black;
    mix-blend-mode: luminosity; /*me gusta!*/
    /* mix-blend-mode:difference; */ /*tendría que cambiar el fondo*/
    /* opacity: 50%; */
    /* padding-bottom: -40svw; */
    /* width: 100%; */
    
    /* font-size:8.8svw; */
    font-size:clamp(1px, 8.8vw, 8rem);
  text-align: center;
  /* font-size:clamp(14px, 3.2vw, 6rem); */
  font-family:"Grotesk";
  color:rgb(248, 248, 248);
  color: rgba(241,240,239,255); /*exacto al fondo de sección 1 */
  /* color: rgb(198, 227, 205); */
  /* mix-blend-mode:color-dodge; */
  /* margin-top:-9.56%; el de arriba era 38 */
  
}


.jfuturo_txt h1 {
    height: 8.6svw;
    /* color:rgb(204, 245, 246); */
    color:rgb(156, 197, 226);
}

@media screen and (max-width: 600px) {
  .jfuturo_txt{
    font-size: 8.2svw;
  }
  .jfuturo_txt h1 {
    height: 8svw;
  }
  
}


/* .jfuturo_txt span {
    line-height: 7;
    display: inline-block;
    transform: scale(1,1.15);
    transform-origin: 0 0;
  } */
button {
    color:gray;
    font-family: "Roboto Mono", monospace;
    font-style: bold;
    text-decoration: underline;
    /* font-size: adjust; */

    border: none;
    background: none;
    cursor: pointer;
    white-space: nowrap;
    display: inline;





    color: orangered;

    font-weight: bold;

    font-size: medium;
}
.sidebar2 {
    margin-top: -4.5svw;
    position: absolute;
    right: 4svw;
  }

.linea {
    margin-left: -30svw;
    opacity: 50%;;
}




.aslideshow {
    width: 300px; /* Ajusta el ancho según tus necesidades */
    height: 50px; /* Ajusta la altura según tus necesidades */
    overflow: hidden;
    border: 1px solid #ccc;
    position: relative;
}


.slideshow {
    height: 200px;
    max-width: 800px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }

.slideshow > div {
    height: 200px;
    width: 2526px;
    /* background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/3/collage.jpg); */
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  .slide-text {
  -webkit-animation: moveSlideshow 12s linear infinite;
          animation: moveSlideshow 12s linear infinite;
}
.aslide-container {
    display: flex;
    -webkit-animation: slideAnimation 10s linear infinite;
            animation: slideAnimation 10s linear infinite; /* Ajusta el tiempo según tus necesidades */
}

.aslide-text {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-animation: slideAnimation 10s infinite;
            animation: slideAnimation 10s infinite; /* Ajusta el tiempo según tus necesidades */
}

@-webkit-keyframes aslideAnimation {
    0% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
    100% {
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%); /* Ajusta el porcentaje según la cantidad de textos */
    }
}

@keyframes aslideAnimation {
    0% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
    100% {
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%); /* Ajusta el porcentaje según la cantidad de textos */
    }
} 




@-webkit-keyframes moveSlideshow {
    100% { 
      -webkit-transform: translateX(-66.6666%); 
              transform: translateX(-66.6666%);  
    }
  } 




@keyframes moveSlideshow {
    100% { 
      -webkit-transform: translateX(-66.6666%); 
              transform: translateX(-66.6666%);  
    }
  }



  
.startup_section{
    margin-left:4svw;
}
.txt2 {
    margin-left: 4svw;
    width: 700px;
}
.startup_section p {
    font-family: Helvetica;
    font-weight: 200;
    font-size: medium;

    
}


.txt1 {
    font-family: Helvetica;
    width: 700px;
    font-size: larger;
    font-weight: 200;
    margin-left: 4svw;
    margin-top:200px;
}
/* div {
    height: 1000px;
} */
/* div {
    height: 1000px;
} */

@font-face {
    font-family: "SpaceMono";   /*Can be any text*/
    src: local("SpaceMono"),
      url(/static/media/SpaceMono-Regular.96985f7a.ttf) format("opentype");
  }

  .short .wrapper {
    position: relative;
    margin-top: -10px;
    margin-left: -3svw;
    margin-right: -3svw;
    /* margin-bottom: -10px; */
    z-index: -1;
    background-color: rgba(241,240,239,255);
    /* overflow: hidden; */
  }
.background-video {
    /* background-color: black; */
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
.short {
    margin-left: 3svw;
    margin-right: 3svw;
}
.short .Header {
    margin-top: 10px;
    display: flex;
    /* position: fixed; */
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}

.short .studios {
    color:#292929;
    font-style: italic;
    /* font-family: monospace; */
}

.short em {
    font-family: "Helvetica";
    font-weight: bolder;
    color: white;
    font-style: normal;
    font-size: 1.1em;
    
}

.short .cta {
    background-color: #1d1d1d;
    font-size: 14px;
    padding: 8px;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 0px;
    margin-bottom: 0px;
    border-radius: 20px;
    color: white;
    
}

.short .cta a {
    color: rgb(212, 212, 212);
    text-decoration: none;
}
.short .texto {
    position: absolute;
    margin-top:400px;
    margin-top: 43vh;
    margin-bottom: -10px;
    color:rgb(0, 0, 0);
    -webkit-animation: color-animation 10s infinite linear alternate;
            animation: color-animation 10s infinite linear alternate;
    /* mix-blend-mode:hard-light; */

}

.short .texto_sub {
    font-family: "SpaceMono";
}
@-webkit-keyframes color-animation {
    /* 0% {
       color: #ffffff28;
    } */
    0% {
        color: black;
    }
    50% {
       color: #ffffff;
    } 
    100% {
        color: rgb(0, 0, 0);
    }
 }
@keyframes color-animation {
    /* 0% {
       color: #ffffff28;
    } */
    0% {
        color: black;
    }
    50% {
       color: #ffffff;
    } 
    100% {
        color: rgb(0, 0, 0);
    }
 }

@media screen and (max-width: 700px) {
    .short .texto {
        font-size: 14px;
        color: black;
    }
    
}
.short .div {
    font-family: "SpaceMono";
    /* font-family: 'Roboto Mono', monospace; */
    /* font-weight: 300; */
    font-weight: 100;
}
.short h4 {
    font-family: "SpaceMono";
    font-size: 3em;
    margin-bottom: -15px;
    letter-spacing: 3px;
    font-weight: 400;
}
@media screen and (max-width: 700px) {
    .short h4 {
        font-size: 2.2em;
        margin-bottom: .1em;
    }
}

.short .texto a {
    color: rgb(0, 0, 0);
    text-decoration: none;
}


.short .Footer {
    margin-top: 85vh;
    display: flex;
    /* position: fixed; */
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    

}
