/* div {
    height: 1000px;
} */

@font-face {
    font-family: "SpaceMono";   /*Can be any text*/
    src: local("SpaceMono"),
      url("../fonts/SpaceMono-Regular.ttf") format("opentype");
  }

  .short .wrapper {
    position: relative;
    margin-top: -10px;
    margin-left: -3svw;
    margin-right: -3svw;
    /* margin-bottom: -10px; */
    z-index: -1;
    background-color: rgba(241,240,239,255);
    /* overflow: hidden; */
  }
.background-video {
    /* background-color: black; */
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
.short {
    margin-left: 3svw;
    margin-right: 3svw;
}
.short .Header {
    margin-top: 10px;
    display: flex;
    /* position: fixed; */
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}

.short .studios {
    color:#292929;
    font-style: italic;
    /* font-family: monospace; */
}

.short em {
    font-family: "Helvetica";
    font-weight: bolder;
    color: white;
    font-style: normal;
    font-size: 1.1em;
    
}

.short .cta {
    background-color: #1d1d1d;
    font-size: 14px;
    padding: 8px;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 0px;
    margin-bottom: 0px;
    border-radius: 20px;
    color: white;
    
}

.short .cta a {
    color: rgb(212, 212, 212);
    text-decoration: none;
}
.short .texto {
    position: absolute;
    margin-top:400px;
    margin-top: 43vh;
    margin-bottom: -10px;
    color:rgb(0, 0, 0);
    animation: color-animation 10s infinite linear alternate;
    /* mix-blend-mode:hard-light; */

}

.short .texto_sub {
    font-family: "SpaceMono";
}
@keyframes color-animation {
    /* 0% {
       color: #ffffff28;
    } */
    0% {
        color: black;
    }
    50% {
       color: #ffffff;
    } 
    100% {
        color: rgb(0, 0, 0);
    }
 }

@media screen and (max-width: 700px) {
    .short .texto {
        font-size: 14px;
        color: black;
    }
    
}
.short .div {
    font-family: "SpaceMono";
    /* font-family: 'Roboto Mono', monospace; */
    /* font-weight: 300; */
    font-weight: 100;
}
.short h4 {
    font-family: "SpaceMono";
    font-size: 3em;
    margin-bottom: -15px;
    letter-spacing: 3px;
    font-weight: 400;
}
@media screen and (max-width: 700px) {
    .short h4 {
        font-size: 2.2em;
        margin-bottom: .1em;
    }
}

.short .texto a {
    color: rgb(0, 0, 0);
    text-decoration: none;
}


.short .Footer {
    margin-top: 85vh;
    display: flex;
    /* position: fixed; */
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    

}